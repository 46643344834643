import React, { useState, useEffect, useMemo } from 'react';
import { Disclosure } from '@headlessui/react';
import { WrenchScrewdriverIcon, CreditCardIcon, DocumentTextIcon, DocumentPlusIcon, UsersIcon, UserGroupIcon, ChevronDownIcon, ArrowRightOnRectangleIcon, BanknotesIcon } from '@heroicons/react/24/outline';
import { useLocation, Link, useHistory } from 'react-router-dom';
import { logout } from '../apiClient/operations/sessionOperations';
import PropTypes from 'prop-types';
import { ROLES } from '../cognito/permissions/permission-maps';
import PermissionsGate from '../cognito/permissions/PermissionsGate';
import { allowedRolesForRoutes } from '../routes/SideBarRoutes';
import { DocumentArrowDownIcon } from '@heroicons/react/24/solid';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function SideBar({ user }) {

    const history = useHistory();
    const location = useLocation();
    const userRoles = user?.roles;

    const [navigation, setNavigation] = useState([
        {
            id: 2,
            name: 'Cotizaciones',
            icon: DocumentPlusIcon,
            current: true,
            route: '/',
            paths: ['/', '/new-quote', '/generated-quotes', '/view-quote'],
            children: [
                { id: 1, name: 'Nueva cotización', current: true, route: '/', paths: ['/', '/new-quote'], allowedRoles: allowedRolesForRoutes.quoterRoles },
                { id: 2, name: 'Lista cotizaciones', current: false, route: '/generated-quotes', paths: ['/generated-quotes', '/view-quote'], allowedRoles: allowedRolesForRoutes.quotesRoles },
            ]
        },
        { id: 3, name: 'Contratos', icon: DocumentTextIcon, current: false, route: '/contracts', paths: ['/contracts', '/contracts/create', `/contracts/view`], allowedRoles: allowedRolesForRoutes.contractsRoles },
        {
            id: 4,
            name: 'Cartera',
            icon: BanknotesIcon,
            current: false,
            paths: ['/subscriptions'],
            children: [
                { id: 1, name: 'Suscripciones', icon: BanknotesIcon, current: false, route: '/subscriptions', paths: ['/subscriptions'], allowedRoles: allowedRolesForRoutes.subscriptions  },
                { id: 2, name: 'Reporte de cobros', icon: BanknotesIcon, current: false, route: '/charges_report', paths: ['/charges_report'], allowedRoles: allowedRolesForRoutes.charges  },
                { id: 3, name: 'Cobranza', icon: BanknotesIcon, current: false, route: '/portfolio/collecting', paths: ['/portfolio/collecting'], allowedRoles: allowedRolesForRoutes.collecting  }
            ],
        },
        {
            id: 5,
            name: 'Clientes',
            icon: UserGroupIcon,
            current: false,
            paths: ['/leads', '/leads/create', '/leads/view', '/prospects', '/prospects/view', '/taxpayers', '/taxpayers/view'],
            children: [
                { id: 1, name: 'Leads', current: false, route: '/leads', paths: ['/leads', '/leads/create', '/leads/view'], allowedRoles: allowedRolesForRoutes.leadsRoles },
                { id: 2, name: 'Prospectos', current: false, route: '/prospects', paths: ['/prospects', '/prospects/view'], allowedRoles: allowedRolesForRoutes.prospectsRoles },
                { id: 3, name: 'Contribuyentes', current: false, route: '/taxpayers', paths: ['/taxpayers', '/taxpayers/view'], allowedRoles: allowedRolesForRoutes.taxpayersRoles },
            ],
        },
        { id: 6, name: 'Links de cobro', icon: CreditCardIcon, current: false, route: '/payment-links', paths: ['/payment-links', '/payment-links/create', `/payment-links/view`], allowedRoles: allowedRolesForRoutes.paymentLinksRoles },
        { id: 7, name: 'Incidencias', icon: WrenchScrewdriverIcon, current: false, route: '/incidents', paths: ['/incidents'], allowedRoles: allowedRolesForRoutes.incidentsRoles },
        { id: 8, name: 'Accesos', icon: UsersIcon, current: false, route: '/access', paths: ['/access'], allowedRoles: allowedRolesForRoutes.accessRoles },
        { id: 9, name: 'Documentos', icon: DocumentArrowDownIcon, current: false, route: '/fiscal-documents', paths: ['/update-documents'], allowedRoles: allowedRolesForRoutes.documentsRoles }
    ]);

    const changeCurrentNavigation = () => {
        const cleanedNav = navigation.map(nav => {
            if (nav.children) {
                const subItems = nav.children.map(subitem => {
                    return {
                        ...subitem,
                        current: subitem.paths.some(item => item == location.pathname || `${item}/${location.pathname.split('/').at(-1)}` == location.pathname)
                    }
                });
                return {
                    ...nav,
                    current: nav.paths.some(item => item == location.pathname || `${item}/${location.pathname.split('/').at(-1)}` == location.pathname),
                    children: subItems
                }
            } else {
                return {
                    ...nav,
                    current: nav.paths.some(item => item == location.pathname || `${item}/${location.pathname.split('/').at(-1)}` == location.pathname)
                }
            }
        });
        setNavigation(cleanedNav);
    }

    useEffect(() => {
        changeCurrentNavigation()
    }, [location.pathname]);

    useEffect(() => {
        if (user) {
            let path = location.pathname;
            const currentLocation = localStorage.getItem("current_location");
            if (currentLocation) {
                localStorage.removeItem("current_location");
                history.replace(currentLocation);
            } else if (path == "/") {
                history.replace(pathToReplace);
            }
        }
    }, []);

    const pathToReplace = useMemo(() => {
        let pathToReplace = "/";
        navigation.every(item => {
            if (item.children) {
                return item.children.every(children => {
                    if (children.allowedRoles.includes(ROLES.all) || userRoles?.some(role => children.allowedRoles.includes(role))) {
                        pathToReplace = children.route;
                        return false;
                    }
                    return true;
                });
            } else if (item.allowedRoles.includes(ROLES.all) || userRoles?.some(role => item.allowedRoles.includes(role))) {
                pathToReplace = item.route;
                return false;
            }
            return true;
        });
        return pathToReplace;
    }, [user]);

    return (
        <div className="w-56 hidden lg:flex flex-grow flex-col min-h-0 h-screen sticky top-0 z-[10] overflow-y-auto scroller bg-v2-blue-text-login pt-4 pb-4">
            <div className="flex flex-shrink-0 items-center justify-center">
                <img
                    className="h-[39.6px] w-[152.1px]"
                    src="https://cdn.fixat.mx/intranet/logo-principal-fixat.png"
                    alt="Logo Fixat"
                />
            </div>
            <div className="w-full pt-6 flex flex-grow flex-col overflow-y-auto scroller">
                <nav className="flex-1 bg-v2-blue-text-login px-2" aria-label="Sidebar">
                    {navigation.map((item) =>
                        !item.children ? (
                            <PermissionsGate key={item.name} allowedRoles={item.allowedRoles}>
                                <Link to={item.route}>
                                    <div className={`${item.current ? 'bg-hover-blue-sidebar' : 'bg-v2-blue-text-login hover:bg-hover-blue-sidebar'} text-white group w-full flex items-center my-2 text-sm font-medium rounded-md cursor-pointer`}>
                                        <div className='group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md'>
                                            <item.icon className='mr-3 flex-shrink-0 h-6 w-6 text-white' aria-hidden="true" />
                                            {item.name}
                                        </div>
                                    </div>
                                </Link>
                            </PermissionsGate>
                        ) : (
                            <PermissionsGate key={item.name} allowedRoles={item.children?.map(subItem => subItem.allowedRoles)?.flat()}>
                                <Disclosure as="div" key={item.name} className="space-y-1">
                                    {({ open }) => (
                                        <>
                                            <Disclosure.Button
                                                className={classNames(
                                                    item.current
                                                        ? 'bg-hover-blue-sidebar'
                                                        : 'bg-v2-blue-text-login hover:bg-hover-blue-sidebar',
                                                    'group w-full flex items-center text-white pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md'
                                                )}

                                            >
                                                <item.icon className="mr-3 h-6 w-6 flex-shrink-0 text-white" aria-hidden="true" />
                                                <span className="flex-1">{item.name}</span>
                                                <ChevronDownIcon className={classNames(open && 'rotate-180',
                                                    'h-5 w-5 flex-shrink-0 text-white transform transition-colors duration-150 ease-in-out'
                                                )} />
                                            </Disclosure.Button>
                                            <Disclosure.Panel className={`pl-5`} >
                                                {item.children.map((subItem) => (
                                                    <PermissionsGate key={subItem.name} allowedRoles={subItem.allowedRoles}>
                                                        <Link to={subItem.route}>
                                                            <div className={`${subItem.current ? 'bg-hover-blue-sidebar' : 'bg-v2-blue-text-login hover:bg-hover-blue-sidebar'} group flex w-full items-center rounded-md py-2 my-1.5 pl-6 pr-2 text-sm font-medium text-white`}>
                                                                {subItem.name}
                                                            </div>
                                                        </Link>
                                                    </PermissionsGate>

                                                ))}
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                            </PermissionsGate>
                        )
                    )}
                </nav>
            </div>
            <div className="flex flex-shrink-0 border-t border-gray-200 py-4 px-2">
                <div className="group block w-full flex-shrink-0">
                    <div className="flex items-center">
                        <div className='flex items-center justify-center h-12 w-12 rounded-full border border-white/30'>
                            <img
                                className="inline-block h-full w-full rounded-full"
                                src={user?.picture || 'https://cdn.fixat.mx/intranet/icono-principal-fixat.png'}
                                alt="Usuario operativo de Fixat"
                            />
                        </div>
                        <div className="ml-3">
                            <p className="text-sm font-medium text-white group-hover:text-white">{user?.givenName}</p>
                            <p className="text-xs font-medium text-white group-hover:text-white pt-1 -ml-[3px] cursor-pointer flex items-center gap-1 underline" onClick={() => logout()}><ArrowRightOnRectangleIcon className='w-5 h-5' /> Cerrar sesión</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

SideBar.propTypes = {
    user: PropTypes.object
}

export default SideBar