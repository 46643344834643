import React, { useState, useEffect, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { CreditCardIcon, ArrowLeftIcon, IdentificationIcon, KeyIcon, DocumentMagnifyingGlassIcon, BanknotesIcon, ComputerDesktopIcon } from '@heroicons/react/24/solid';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { AlertContext } from '../../../context/AlertContext';
import Nav from '../../../hooks/Nav';
import { ContractorData, InvoicingData } from '../../contracts/Steps/GeneralData';
import Tabs from '../../../tailwindUI/Tabs';
import PaymentsBalance from '../../contracts/ViewContract/PaymentsBalance';
import { getAccount, updateAccountStatus } from '../../../apiClient/operations/accountsOperations';
import { ROLES } from '../../../cognito/permissions/permission-maps';
import Efirma from './Efirma';
import TaxSituation from './TaxSituation';
import SkeletonLoader from '../../../tailwindUI/SkeletonLoader';
import PermissionsGate from '../../../cognito/permissions/PermissionsGate';
import SubscriptionInfo from '../../billing/SubscriptionInfo';
import StatusMenuButton from '../../../tailwindUI/StatusMenuButton';
import TaxpayerPortalInfo from '../../billing/TaxpayerPortalInfo';
import Badge from '../../../tailwindUI/Badge';

const allowedRolesToEdit = {
    'lead': [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.collections, ROLES.customerService, ROLES.acquisitions, ROLES.operationsAtc],
    'prospect': [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.sales, ROLES.operations, ROLES.collections, ROLES.customerService, ROLES.acquisitions, ROLES.operationsAtc],
    'taxpayer': [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.sales, ROLES.operations, ROLES.operationsAtc],
    'customer': [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.sales, ROLES.operations, ROLES.operationsAtc]
}

const modules = [
    {
        id: 1,
        name: 'Información',
        icon: IdentificationIcon,
        current: true,
        href: '#info',
        allowedRoles: {
            'lead': [ROLES.all],
            'prospect': [ROLES.all],
            'taxpayer': [ROLES.all],
            'customer': [ROLES.all]
        }
    },
    {
        id: 2,
        name: 'Balance de pagos',
        icon: CreditCardIcon,
        current: false,
        href: '#payment-balance',
        allowedRoles: {
            'taxpayer': [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.sales, ROLES.operations, ROLES.collections, ROLES.collections_manager, ROLES.operationsAtc],
            'customer': [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.sales, ROLES.operations, ROLES.collections, ROLES.collections_manager, ROLES.operationsAtc],
            'prospect': [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.sales, ROLES.operations, ROLES.collections, ROLES.collections_manager, ROLES.operationsAtc]
        }
    },
    {
        id: 3,
        name: 'Accesos al SAT',
        icon: KeyIcon,
        current: false,
        href: '#sat-access',
        allowedRoles: {
            'prospect': [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.operations, ROLES.customerService, ROLES.collections, ROLES.collections_manager, ROLES.acquisitions, ROLES.operationsAtc],
            'taxpayer': [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.operations, ROLES.customerService, ROLES.collections, ROLES.collections_manager, ROLES.operationsAtc],
            'customer': [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.operations, ROLES.customerService, ROLES.collections, ROLES.collections_manager, ROLES.operationsAtc]
        }
    },
    {
        id: 4,
        name: 'Situación fiscal',
        icon: DocumentMagnifyingGlassIcon,
        current: false,
        href: '#tax-situation',
        allowedRoles: {
            'prospect': [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.operations, ROLES.collections, ROLES.operationsAtc],
            'taxpayer': [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.operations, ROLES.collections, ROLES.operationsAtc],
            'customer': [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.operations, ROLES.collections, ROLES.operationsAtc]
        }
    },
    {
        id: 5,
        name: 'Suscripciones',
        icon: BanknotesIcon,
        current: false,
        href: '#subscription',
        accountType: ['taxpayer'],
        allowedRoles: {
            'taxpayer': [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.operations, ROLES.collections, ROLES.operationsAtc],
            'customer': [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.operations, ROLES.collections, ROLES.operationsAtc]
        }
    }
]

const accountType = [
    {
        "name": "taxpayer",
        "label": "Contribuyente"
    },
    {
        "name": "customer",
        "label": "Cliente"
    }
]

const getAccountTypeLabel = (type) => (
    accountType.find((acc) => acc.name == type) != null ?
        accountType.find((acc) => acc.name == type).label : ''
)
function ViewAccount({ user }) {

    const userRoles = user?.roles;
    const { accountId } = useParams();
    const history = useHistory();
    const { state, hash } = useLocation();
    const { setAlert } = useContext(AlertContext);
    const [isLoading, setIsloading] = useState(true);
    const [account, setAccount] = useState(null);
    const [accountStatus, setAccountStatus] = useState(null);
    const [paymentBalance, setPaymentBalance] = useState(null);
    const [tabs, setTabs] = useState(modules);


    useEffect(async () => {
        if (account) return;

        if (state?.account) {
            setAccount(state.account);
            setTimeout(() => {
                setIsloading(false);
            }, 500)
            return;
        }
        setIsloading(true);
        try {
            const account = await getAccount(accountId);
            setAccount(account);
        } catch (e) {
            setIsloading(false);
            setAlert({ active: true, type: 'error', message: 'Error al procesar tu solicitud' })
        }
        if (isLoading) setIsloading(false);
    }, [state, account]);

    useEffect(async () => {
        setAccountStatus(account?.status);
        // if (!account) return;
        // try {
        //     // const res = await getContracts(1, 1, account?.name);
        //     const res = account?.contract_id ? await getContract(account?.contract_id) : null
        //     setContract(res);
        // } catch (e) {
        //     setAlert({ active: true, type: 'error', message: e.message || 'Hubo un error al obtener la información.' })
        // }
    }, [account])

    useEffect(async () => {
        if (!account || !user) return;
        let tabs = filteredTabs;
        if (hash && tabs.some(tab => tab.href == hash)) {
            tabs = tabs?.map((tab) => {
                tab.current = hash == tab.href;
                return tab;
            })
        } else {
            tabs = tabs?.map((tab, i) => {
                tab.current = i == 0;
                return tab;
            })
        }
        setTabs(tabs);
    }, [user, account, hash]);

    const filteredTabs = useMemo(() => {
        const modulesPortal = [...modules, {
            id: 6,
            name: 'Portal del Contribuyente',
            icon: ComputerDesktopIcon,
            current: false,
            href: '#taxpayer-portal',
            allowedRoles: {
                'taxpayer': [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.operations, ROLES.collections],
                'customer': [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.operations, ROLES.collections]
            },
            disabled: account?.account_type != 'taxpayer'
        }]
        const filteredTabs = [...modulesPortal].filter(tab => tab.allowedRoles[account?.account_type]?.includes(ROLES.all) || user?.roles?.some(role => tab.allowedRoles[account?.account_type]?.includes(role)));
        return filteredTabs;
    }, [user, account]);

    const handleGoBack = () => {
        history.goBack();
    }

    const updateStatus = async (account, status) => {
        try {
            setAccountStatus(status);
            await updateAccountStatus(account?.id, status);
        } catch (e) {
            setAccountStatus(account?.status);
            setAlert({ active: true, type: 'error', message: e.message || 'Error al procesar tu solicitud' })
        }
    }


    return (
        <>
            <div className='w-full'>
                <div className="w-full">
                    <Nav>
                        <div className='text-white font-medium text-xl md:text-3xl xl:text-4xl flex gap-2 items-center'>
                            <ArrowLeftIcon className='w-10 md:w-12 h-10 md:h-12 cursor-pointer' onClick={handleGoBack} />
                            Contribuyente
                        </div>
                    </Nav>
                </div>
                <div className="w-full px-4">
                    <div className="md:block xs:block w-full">
                        <div className='md:w-full xs:hidden lg:block w-full'>
                            <div className="w-full text-3xl text-v2-input-text flex justify-between gap-4">
                                <div className="w-full mt-4 flex justify-between">
                                    <div className='text-gray-900 font-medium text-xl md:text-3xl xl:text-4xl flex gap-2'>
                                        <ArrowLeftIcon className='w-10 md:w-12 h-10 md:h-12 cursor-pointer' onClick={handleGoBack} />
                                        <div>
                                            {account?.name || <div className='w-[50vw]'><SkeletonLoader size='3xl' /></div>}
                                            <div className='flex flex-row gap-5'>
                                                <p className='text-lg text-gray-700 h-7'>{account?.taxpayer?.tax_id ? `RFC: ${account?.taxpayer?.tax_id}` : 'RFC: -'}</p>
                                                {(account?.account_type && (!(account?.account_type != 'taxpayer') || !(account?.account_type != 'customer'))) ? <Badge text={getAccountTypeLabel(account?.account_type)} /> : <></>}
                                            </div>

                                        </div>
                                    </div>
                                    {account && (
                                        <PermissionsGate allowedRoles={[ROLES.root]}>
                                            <StatusMenuButton
                                                status={accountStatus}
                                                onChange={(status) => updateStatus(account, status)} />
                                        </PermissionsGate>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="xs:w-full md:w-full xs:mt-20 lg:mt-0 mb-4 flex flex-col gap-4">
                            <div className='lg:hidden space-y-4'>
                                <div className="w-full space-y-4">
                                    <div className='text-gray-900 text-xl md:text-3xl xl:text-4xl'>
                                        {account?.name || <div className='w-[50vw]'><SkeletonLoader size='3xl' /></div>}
                                        {account?.taxpayer?.tax_id && (
                                            <p className='text-lg text-gray-700'>RFC: {account?.taxpayer?.tax_id}</p>
                                        )}
                                        {account && (
                                            <PermissionsGate allowedRoles={[ROLES.root, ROLES.administrators, ROLES.help_desk]}>
                                                <div className='w-full mt-4'>
                                                    <StatusMenuButton
                                                        status={accountStatus}
                                                        onChange={(status) => updateStatus(account, status)} />
                                                </div>
                                            </PermissionsGate>

                                        )}
                                    </div>
                                </div>
                            </div>
                            {isLoading ? (
                                <>
                                    <div className='w-full hidden lg:flex h-[54px] items-center gap-4'>
                                        <div className='w-full max-w-[10rem]'><SkeletonLoader size='2xl' /></div>
                                        <div className='w-full max-w-[10rem]'><SkeletonLoader size='2xl' /></div>
                                        <div className='w-full max-w-[10rem]'><SkeletonLoader size='2xl' /></div>
                                        <div className='w-full max-w-[10rem]'><SkeletonLoader size='2xl' /></div>
                                        <div className='w-full max-w-[10rem]'><SkeletonLoader size='2xl' /></div>
                                    </div>
                                    <div className="overflow-hidden bg-white shadow rounded-lg border border-gray-200">
                                        <div className="px-4 py-5 sm:px-6 flex justify-between">
                                            <div className='w-[30vw]'>
                                                <SkeletonLoader />
                                            </div>
                                        </div>
                                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                                <div className="sm:col-span-1">
                                                    <SkeletonLoader />
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <SkeletonLoader />
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <SkeletonLoader />
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <SkeletonLoader />
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <SkeletonLoader />
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <SkeletonLoader />
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <SkeletonLoader />
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <SkeletonLoader />
                                                </div>
                                            </dl>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {account && (
                                        <>
                                            <Tabs items={tabs} setItems={setTabs} />
                                            {(() => {
                                                switch (tabs.find(tab => tab.current)?.id) {
                                                    case 1: return (
                                                        <>
                                                            <PermissionsGate allowedRoles={tabs.find(tab => tab.id == 1)?.allowedRoles[account?.account_type]}>
                                                                <ContractorData
                                                                    data={account}
                                                                    setData={setAccount}
                                                                    isLoadingData={isLoading}
                                                                    isTaxpayer={account?.account_type == 'taxpayer' || account?.account_type == 'customer'}
                                                                    editable={userRoles?.some(role => allowedRolesToEdit[account?.account_type]?.includes(role))} />
                                                                {(account?.account_type == 'taxpayer' || account?.account_type == 'customer') && (
                                                                    <InvoicingData
                                                                        accountData={account}
                                                                        data={account?.billing}
                                                                        setData={setAccount}
                                                                        isLoadingData={isLoading}
                                                                        editable={userRoles?.some(role => allowedRolesToEdit[account?.account_type]?.includes(role))} />
                                                                )}
                                                            </PermissionsGate>
                                                        </>
                                                    )
                                                    case 2: return (
                                                        <PermissionsGate allowedRoles={tabs.find(tab => tab.id == 2)?.allowedRoles[account?.account_type]}>
                                                            <PaymentsBalance account={account} accountId={account?.id} paymentBalance={paymentBalance} setPaymentBalance={setPaymentBalance} />
                                                        </PermissionsGate>
                                                    )
                                                    case 3: return (
                                                        <PermissionsGate allowedRoles={tabs.find(tab => tab.id == 3)?.allowedRoles[account?.account_type]}>
                                                            <Efirma account={account} />
                                                        </PermissionsGate>
                                                    )
                                                    case 4: return (
                                                        <PermissionsGate allowedRoles={tabs.find(tab => tab.id == 4)?.allowedRoles[account?.account_type]}>
                                                            <TaxSituation account={account} />
                                                        </PermissionsGate>
                                                    )
                                                    case 5: return (
                                                        <PermissionsGate allowedRoles={tabs.find(tab => tab.id == 5)?.allowedRoles[account?.account_type]}>
                                                            <SubscriptionInfo account={account} />
                                                        </PermissionsGate>
                                                    )
                                                    case 6: return (
                                                        <PermissionsGate allowedRoles={tabs.find(tab => tab.id == 5)?.allowedRoles[account?.account_type]}>
                                                            <TaxpayerPortalInfo account={account} />
                                                        </PermissionsGate>
                                                    )
                                                    default: return null;
                                                }
                                            })()}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

ViewAccount.propTypes = {
    user: PropTypes.object
}

export default ViewAccount;