import React from 'react';
import { AccountProvider } from '../context/AccountContext';
import { Switch, useHistory, Router } from 'react-router-dom';
import { ROLES } from '../cognito/permissions/permission-maps';
import CreatePaymentLink from '../components/billing/CreatePaymentLink';
import Quoter from '../components/quotes/Quoter';
import NewQuote from '../components/quotes/NewQuote';
import GeneratedQuotes from '../components/quotes/GeneratedQuotes';
import ViewQuote from '../components/quotes/ViewQuote';
import ViewLink from '../components/billing/ViewLink';
import Contracts from '../components/contracts/Contracts';
import CreateContract from '../components/contracts/CreateContract';
import ViewContract from '../components/contracts/ViewContract/ViewContract';
import NewLead from '../components/accounts/leads/NewLead';
import PaymentLink from '../components/billing/PaymentLink';
import PermissionsRoute from '../cognito/permissions/PermissionsRoute';
import CreateTicket from '../components/support/CreateTicket';
import Leads from '../components/accounts/leads/Leads';
import Taxpayers from '../components/accounts/taxpayers/Taxpayers';
import Prospects from '../components/accounts/prospects/Prospects';
import ViewAccount from '../components/accounts/viewAccount/ViewAccount';
import Subscriptions from '../components/subscriptions/Subscriptions';
import Charges from '../components/charges/Charges';
import ChargesReport from '../components/charges/ChargesReport';
import Collecting from '../components/portfolio/Collecting';
import NewAccess from '../components/access/NewAccess';
import UpdateDocumentsDate from '../components/tools/UpdateDocumentsDate';

export const allowedRolesForRoutes = {
    quoterRoles: [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.sales, ROLES.collections, ROLES.collections_manager],
    createQuoteRoles: [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.sales, ROLES.collections, ROLES.collections_manager],
    quotesRoles: [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.sales, ROLES.collections, ROLES.operations, ROLES.collections_manager, ROLES.operationsAtc],
    viewQuoteRoles: [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.sales, ROLES.collections, ROLES.operations, ROLES.collections_manager, ROLES.operationsAtc],
    
    contractsRoles: [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.sales, ROLES.collections, ROLES.operations, ROLES.collections_manager, ROLES.operationsAtc],
    createContractRoles: [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.sales, ROLES.collections, ROLES.collections_manager, ROLES.operationsAtc],
    viewContractRoles: [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.sales, ROLES.collections, ROLES.operations, ROLES.collections_manager, ROLES.operationsAtc],
    
    leadsRoles: [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.sales, ROLES.executives, ROLES.collections, ROLES.customerService, ROLES.acquisitions, ROLES.collections_manager, ROLES.operationsAtc],
    viewLeadRoles: [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.sales, ROLES.executives, ROLES.collections, ROLES.customerService, ROLES.acquisitions, ROLES.collections_manager, ROLES.operationsAtc],
    createLeadRoles: [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.sales, ROLES.collections, ROLES.customerService, ROLES.acquisitions, ROLES.collections_manager, ROLES.operationsAtc],
    
    prospectsRoles: [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.sales,ROLES. operations, ROLES.collections, ROLES.customerService, ROLES.acquisitions, ROLES.collections_manager, ROLES.operationsAtc],
    viewProspectRoles: [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.sales, ROLES.operations, ROLES.collections, ROLES.customerService, ROLES.acquisitions, ROLES.collections_manager, ROLES.operationsAtc],

    subscriptions: [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.collections, ROLES.acquisitionsCollection, ROLES.collections_manager, ROLES.operationsAtc],
    charges: [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.collections, ROLES.acquisitionsCollection, ROLES.collections_manager],
    collecting: [ROLES.root, ROLES.help_desk, ROLES.administrators],
    
    taxpayersRoles: [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.sales, ROLES.operations, ROLES.collections, ROLES.customerService, ROLES.collections_manager, ROLES.operationsAtc],
    viewTaxpayerRoles: [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.sales, ROLES.operations, ROLES.collections, ROLES.customerService, ROLES.collections_manager, ROLES.operationsAtc],
    
    paymentLinksRoles: [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.sales, ROLES.collections, ROLES.customerService, ROLES.acquisitions, ROLES.collections_manager, ROLES.operationsAtc],
    createPaymentLinkRoles: [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.sales, ROLES.collections, ROLES.customerService, ROLES.acquisitions, ROLES.collections_manager, ROLES.operationsAtc],
    viewPaymentLinkRoles: [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.sales, ROLES.collections, ROLES.customerService, ROLES.acquisitions, ROLES.collections_manager, ROLES.operationsAtc],
    
    incidentsRoles: [ROLES.all],

    accessRoles: [ROLES.root, ROLES.help_desk],
    
    documentsRoles: [ROLES.root, ROLES.help_desk]
    
}

const RoutesApp = () => {
    let history = useHistory();
    return (
        <>
            <AccountProvider>
                <Router history={history}>
                    <Switch>
                        {/*Quotes */}
                        <PermissionsRoute exact path='/' allowedRoles={allowedRolesForRoutes.quoterRoles} component={Quoter} />
                        <PermissionsRoute exact path='/new-quote' allowedRoles={allowedRolesForRoutes.createQuoteRoles} component={NewQuote} />
                        <PermissionsRoute exact path='/generated-quotes' allowedRoles={allowedRolesForRoutes.quotesRoles} component={GeneratedQuotes} />
                        <PermissionsRoute exact path='/view-quote' allowedRoles={allowedRolesForRoutes.viewQuoteRoles} component={ViewQuote} />

                        {/*Contracts */}
                        <PermissionsRoute exact path='/contracts' allowedRoles={allowedRolesForRoutes.contractsRoles} component={Contracts} />
                        <PermissionsRoute exact path='/contracts/create' allowedRoles={allowedRolesForRoutes.createContractRoles} component={CreateContract} />
                        <PermissionsRoute exact path='/contracts/view/:contractId' allowedRoles={allowedRolesForRoutes.viewContractRoles} component={ViewContract} />

                        {/*Portfolio */}
                        <PermissionsRoute exact path='/subscriptions' allowedRoles={allowedRolesForRoutes.subscriptions} component={Subscriptions} />
                        <PermissionsRoute exact path='/charges' allowedRoles={allowedRolesForRoutes.charges} component={Charges} />
                        <PermissionsRoute exact path='/charges_report' allowedRoles={allowedRolesForRoutes.charges} component={ChargesReport} />
                        <PermissionsRoute exact path='/portfolio/collecting' allowedRoles={allowedRolesForRoutes.collecting} component={Collecting} />

                        {/*Leads */}
                        <PermissionsRoute exact path='/leads' allowedRoles={allowedRolesForRoutes.leadsRoles} component={Leads} />
                        <PermissionsRoute exact path='/leads/view/:accountId' allowedRoles={allowedRolesForRoutes.viewLeadRoles} component={ViewAccount} />
                        <PermissionsRoute exact path='/leads/create' allowedRoles={allowedRolesForRoutes.createLeadRoles} component={NewLead} />

                        {/*Prospects */}
                        <PermissionsRoute exact path='/prospects' allowedRoles={allowedRolesForRoutes.prospectsRoles} component={Prospects} />
                        <PermissionsRoute exact path='/prospects/view/:accountId' allowedRoles={allowedRolesForRoutes.viewProspectRoles} component={ViewAccount} />

                        {/*Taxpayers */}
                        <PermissionsRoute exact path='/taxpayers' allowedRoles={allowedRolesForRoutes.taxpayersRoles} component={Taxpayers} />
                        <PermissionsRoute exact path='/taxpayers/view/:accountId' allowedRoles={allowedRolesForRoutes.viewTaxpayerRoles} component={ViewAccount} />

                        {/*Payment links */}
                        <PermissionsRoute exact path='/payment-links' allowedRoles={allowedRolesForRoutes.paymentLinksRoles} component={PaymentLink} />
                        <PermissionsRoute exact path='/payment-links/create' allowedRoles={allowedRolesForRoutes.createPaymentLinkRoles} component={CreatePaymentLink} />
                        <PermissionsRoute exact path='/payment-links/view/:id' allowedRoles={allowedRolesForRoutes.viewPaymentLinkRoles} component={ViewLink} />

                        {/*Incidents */}
                        <PermissionsRoute exact path='/incidents' allowedRoles={allowedRolesForRoutes.incidentsRoles} component={CreateTicket} />

                        {/*Accesos */}
                        <PermissionsRoute exact path='/access' allowedRoles={allowedRolesForRoutes.accessRoles} component={NewAccess} />

                        <PermissionsRoute exact path='/fiscal-documents' allowedRoles={allowedRolesForRoutes.documentsRoles} component={UpdateDocumentsDate} />
                    </Switch>
                </Router>
            </AccountProvider>
        </>
    );
}

export default RoutesApp;