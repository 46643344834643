import React, { useState, useEffect, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { DocumentTextIcon, CreditCardIcon } from '@heroicons/react/24/solid';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { AlertContext } from '../../../context/AlertContext';
import Nav from '../../../hooks/Nav';
import { ContractorData, InvoicingData } from '../Steps/GeneralData';
import Tabs from '../../../tailwindUI/Tabs';
import ContractDetails from './ContractDetails';
import PaymentsBalance from './PaymentsBalance';
import SkeletonLoader from '../../../tailwindUI/SkeletonLoader';
import { activeContract, getContract } from '../../../apiClient/operations/contractsOperations';
import BreadCrumb from '../../../tailwindUI/BreadCrumb';
import PermissionsGate from '../../../cognito/permissions/PermissionsGate';
import { ROLES } from '../../../cognito/permissions/permission-maps';
import StatusMenuButton from '../../../tailwindUI/StatusMenuButton';

function ViewContract() {
    const user = JSON.parse(localStorage.getItem('user')) || {};
    const { contractId } = useParams();
    const history = useHistory();
    const location = useLocation();
    const { setAlert } = useContext(AlertContext);
    const [isLoading, setIsloading] = useState(true);
    const [contract, setContract] = useState(null);
    const [isNewContract, setIsNewContract] = useState(false);
    const [paymentBalance, setPaymentBalance] = useState(null);
    const [contractStatus, setContractStatus] = useState(null);
    const [tabs, setTabs] = useState([
        { id: 1, name: 'Contrato y acuerdos de pago', icon: DocumentTextIcon, current: true },
        { id: 2, name: 'Pagos efectuados', icon: CreditCardIcon, current: false }
    ]);
    const roadMap = [
        { name: 'Contratos', url: '/contracts', current: false },
        { name: 'Detalles del contrato', url: '/contracts/view', current: true },
    ];

    useEffect(() => {
        if (!contract) return;
        setContractStatus(contract?.status);
    }, [contract])

    useEffect(async () => {
        if (contract) return;
        const state = location.state;
        let contractData;
        let isNewContract = false;
        if (state?.contract) {
            contractData = state.contract;
            isNewContract = state.contract != null;
        } else {
            setIsloading(true);
            try {
                contractData = await getContract(contractId);
            } catch (e) {
                setIsloading(false);
                setAlert({ active: true, type: 'error', message: 'Error al procesar tu solicitud' })
            }
        }
        if (isLoading) setIsloading(false);
        setContract(contractData);
        setIsNewContract(isNewContract);
    }, [location, contract]);

    const handleGoBack = () => {
        history.goBack();
    }

    const renderTab = useMemo(() => {
        switch (tabs.find(tab => tab.current)?.id) {
            case 1: return <ContractDetails contract={contract} />;
            case 2: return <PaymentsBalance account={contract.account} contract={contract} accountId={contract?.account?.id} paymentBalance={paymentBalance} setPaymentBalance={setPaymentBalance} />;
            default: return null;
        }
    }, [tabs, contract, paymentBalance]);

    const updateStatus = async (contract, status) => {
        try {
            await activeContract({}, contract?.id);
            setContract({
                ...contract,
                status: "active"
            })
            setContractStatus(status)
            setAlert({ active: true, type: 'success', message: 'El contrato ha sido activado'})
        } catch (e) {
            setContractStatus(contract?.status)
            setAlert({ active: true, type: 'error', message: e.message || 'Error al procesar tu solicitud' })
        }
    }

    return (
        <>
            <div className='w-full'>
                <div className="w-full">
                    <Nav user={user}>
                        <div className='text-white lg:text-gray-900 font-medium text-xl md:text-3xl xl:text-4xl flex gap-2 items-center'>
                            {!isNewContract && <ArrowLeftIcon className='w-10 md:w-12 h-10 md:h-12 cursor-pointer' onClick={handleGoBack} />}
                            Detalles de contrato
                        </div>
                    </Nav>
                </div>
                <div className="w-full px-4">
                    <div className='mt-20 lg:mt-4'>
                        <BreadCrumb roadMap={roadMap} />
                    </div>
                    <div className="md:block xs:block w-full">
                        <div className='md:w-full xs:hidden lg:block w-full'>
                            <div className="w-full text-3xl text-v2-input-text font-bold flex justify-between gap-4">
                                <div className="w-full mt-5">
                                    <div className='text-white md:text-gray-900 font-medium text-xl md:text-3xl xl:text-4xl flex gap-2 items-center'>
                                        {!isNewContract && <ArrowLeftIcon className='w-10 md:w-12 h-10 md:h-12 cursor-pointer' onClick={handleGoBack} />}
                                        Detalles de contrato
                                    </div>

                                </div>
                                {contract && (
                                    <PermissionsGate allowedRoles={[ROLES.root, ROLES.help_desk]}>
                                        <StatusMenuButton
                                                status={contractStatus}
                                                isContract={true}
                                                disabled={contractStatus != 'pending'}
                                                onChange={(status) => updateStatus(contract, status)} 
                                                /> 
                                    </PermissionsGate>
                                )}
                            </div>
                        </div>
                        <div className="xs:w-full md:w-full mt-4 mb-4 flex flex-col gap-4">
                            {isNewContract && (
                                <div className="overflow-hidden bg-white shadow rounded-lg border border-gray-200">
                                    <div className="px-4 py-5 sm:px-6 flex justify-between gap-4">
                                        <div className="xs:w-full md:w-6/12">
                                            <div className="w-full xs:text-center md:text-left text-green-600 text-xl font-bold leading-8 tracking-wide">
                                                ¡Felicidades!
                                                <span className="block">
                                                    Terminaste el proceso.
                                                </span>
                                            </div>
                                            <div className="text-gray-500 xs:text-center md:text-left pt-2">
                                                Aquí puedes ver todos los detalles del contrato generado, acuerdos de pago y descargar los documentos.
                                            </div>
                                        </div>
                                        <div className="xs:hidden md:block w-4/12 text-center">
                                            <div className="w-full flex justify-center">
                                                <div className="w-56 text-center">
                                                    <img
                                                        src="https://cdn-fixat-mx.s3.amazonaws.com/intranet/fixi_thankyou.png"
                                                        alt="Fixi - Thank you"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <ContractorData data={contract?.contractor} editable={false} isLoadingData={isLoading} />
                            <InvoicingData data={contract?.billing} editable={false} isLoadingData={isLoading} expandible />
                            {!isLoading ? (
                                <>
                                    <Tabs items={tabs} setItems={setTabs} />
                                    {renderTab}
                                </>
                            ) : (
                                <>
                                    <Loader />
                                </>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

const Loader = () => {
    return (
        <div className='space-y-4'>
            <div className="overflow-hidden bg-white shadow rounded-lg border border-gray-200">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">Contrato</h3>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div className="sm:col-span-1">
                            <SkeletonLoader />
                        </div>
                        <div className="sm:col-span-1">
                            <SkeletonLoader />
                        </div>
                        <div className="sm:col-span-1">
                            <SkeletonLoader />
                        </div>
                        <div className="sm:col-span-1">
                            <SkeletonLoader />
                        </div>
                        <div className="sm:col-span-1">
                            <SkeletonLoader />
                        </div>
                        <div className="sm:col-span-1">
                            <SkeletonLoader />
                        </div>
                    </dl>
                </div>
            </div>
            <div>
                <dl className={`gap-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4`}>
                    {[...Array(4).keys()].map((item) => (
                        <div
                            key={item}
                            className="relative overflow-hidden rounded-lg bg-white p-4 shadow flex">
                            <dt>
                                <div className="h-12 w-12 rounded-md animate-pulse bg-gray-300 p-3">
                                    <div className="h-6 w-6" aria-hidden="true" />
                                </div>
                            </dt>
                            <div className="w-full">
                                <dd className="ml-2">
                                    <p className="truncate text-sm font-medium text-gray-500 mb-2"><SkeletonLoader /></p>
                                    <p className="truncate text-sm font-medium text-gray-500"><SkeletonLoader /></p>
                                </dd>
                            </div>
                        </div>
                    ))}
                </dl>
            </div>
        </div>
    )
}

ViewContract.propTypes = {
    services: PropTypes.array,
    servicePeriods: PropTypes.array,
    setStep: PropTypes.func,
    paymentInstruction: PropTypes.object,
    setPaymentInstruction: PropTypes.func
}

ViewContract.defaultProps = {
    services: [],
    servicePeriods: []
}

export default ViewContract;