import apiClient from '../apiClient';
import { apiBaseUrl } from '../../config';

export const createLink = async (data, req) => {
    return apiClient.post(`${apiBaseUrl}/links-manager/`, data, req)
    .then(response => { return response });
}

export const invalidateConnectionOperation = async (id, connection_id, req) => {
    const body = {connection_id: connection_id}
    return apiClient.post(`${apiBaseUrl}/connections/admin/${id}/invalidate/`, body, req)
    .then(response => { return response });
}

export const resetPasswordTaxpayerPortal = async (email, send_email, type) => {
    return apiClient.post(`${apiBaseUrl}/sessions/taxpayer/${email}/reset-password/request`, {
        "type": type,
        "send_email": send_email
    })
    .then(response => { return response });
}