import React, { useState, useEffect } from 'react';
import Nav from '../../hooks/Nav';
import useFormatDate from '../../hooks/useFormatDate';
import Sort from '../../tailwindUI/Sort';
import SearchBar from '../../tailwindUI/SearchBar';
import EmptyState from '../../tailwindUI/EmptyState';
import Table from '../../tailwindUI/Table';
import Pagination from '../../tailwindUI/Pagination';
import usePagination from '../../tailwindUI/usePagination';
import useSort from '../../tailwindUI/useSort';
import useFilter from '../../tailwindUI/useFilter';
import LongAlert from '../../tailwindUI/LongAlert';
import useScrollPosition from '../../hooks/useScrollPosition';
import { getGeneralBalance, getPortfolioClients } from '../../apiClient/operations/collectionOperations';
import useFormatterCurrency from '../../hooks/useFormatterCurrency';
import { AdjustmentsHorizontalIcon, TrashIcon } from '@heroicons/react/24/outline';
import { CalendarIcon, CurrencyDollarIcon, ReceiptPercentIcon, UsersIcon } from '@heroicons/react/24/solid';
import Filters from '../../tailwindUI/Filters';
import Notification from '../../tailwindUI/Notification';
import DatePicker from '../../hooks/DatePicker';
import SlideOver from '../../tailwindUI/SlideOver';
import Stats from '../../tailwindUI/Stats';
import moment from 'moment';

const tableColumns = [
    { heading: 'Cliente', value: 'account.name', subvalue: 'account.tax_id', thirdValue: 'account.email', main: true, },
    { heading: 'Tipo de cliente', value: 'account.translated_account_type', align: 'center' },
    { heading: 'Saldo anterior', value: 'formatted_previous_amount', align: 'center' },
    { heading: 'Cargo del mes', value: 'formatted_amount', align: 'center' },
    { heading: 'Saldo pendiente', value: 'formatted_due_amount', align: 'center' },
    { heading: 'Tipo de cuenta', value: 'status', badge: true, align: 'center' },
    { heading: 'Límite de pago', value: 'formatted_payment_date', align: 'center' },
];

const accountType = {
    customer: "Cliente",
    taxpayer: "Contribuyente",
    prospect: "Prospecto"
}

const locale = {
    localize: {
        day: n => ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'][n],
        month: n => ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'][n]
    },
    formatLong: {
        date: () => 'dd/MM/yyyy'
    }
}

function Portfolio() {

    const user = JSON.parse(localStorage.getItem('user')) || {};
    const paginate = usePagination();
    const { formatDate } = useFormatDate();
    const { formatterCurrency } = useFormatterCurrency();

    const sortItem = useSort();
    const filterItem = useFilter();
    const setScrollPosition = useScrollPosition();
    const [isLoadingBalance, setIsLoadingBalance] = useState(false);
    const [generalBalance, setGeneralBalance] = useState(null);
    const [stats, setStats] = useState([]);
    const [billingPeriod, setBillingPeriod] = useState(null);
    const [dateSelected, setDateSelected] = useState(new Date());
    const [maxDate] = useState(new Date());
    const [searchInput, setSearchInput] = useState(null);
    const [sortString, setSortString] = useState('');
    const [filterString, setFilterString] = useState('');
    const [openSlide, setOpenSlide] = useState(false);
    const [pagination, setPagination] = useState(null);
    const [currentPage, setCurrenPage] = useState(1);
    const [filtersApplied, setFiltersApplied] = useState(false);
    const [clients, setClients] = useState([]);
    const [totalClients, setTotalClients] = useState(0);
    const [isLoadingClients, setIsLoadingClients] = useState(false);
    const [error, setError] = useState(null);
    const [notification, setNotification] = useState(false);
    const [sortOptions, setSortOptions] = useState([
        { id: 1, name: 'Límite de pago descendente', field: 'next_payment_date', sort: 'reverse', active: true },
        { id: 2, name: 'Límite de pago ascendente', field: 'next_payment_date', sort: 'direct', active: false },
        { id: 3, name: 'Nombre descendente', field: 'account.name', sort: 'reverse', active: false },
        { id: 4, name: 'Nombre ascendente', field: 'account.name', sort: 'direct', active: false }
    ]);
    const [filters, setFilters] = useState([
        {
            id: 1,
            name: 'Tipo de cuenta',
            value: 'status',
            open: false,
            options: [
                { id: 1, value: 'receivable', label: 'Por cobrar', applied: false, checked: false, filter_id: 1 },
                { id: 2, value: 'collected', label: "Al corriente", applied: false, checked: false, filter_id: 1 },
                { id: 3, value: 'early_defaulter', label: "Morosidad temprana", applied: false, checked: false, filter_id: 1 },
                { id: 4, value: 'late_defaulter', label: "Morosidad tardía", applied: false, checked: false, filter_id: 1 },
                { id: 5, value: 'overdue', label: "Vencidas", applied: false, checked: false, filter_id: 1 }
            ],
        }
    ]);

    useEffect(() => {
        const utcDate = moment.utc(dateSelected);
        const start = utcDate.startOf('month').valueOf();
        const end = utcDate.endOf('month').valueOf() - 999;

        setBillingPeriod({
            start,
            end
        })
    }, [dateSelected]);

    useEffect(() => {
        if (!billingPeriod) return;
        handleGetClientsData(currentPage);
        setScrollPosition(0);
        return () => {
            setClients([]);
        }
    }, [filterString, sortString, currentPage, searchInput]);

    useEffect(() => {
        if (!billingPeriod) return;
        handleGetBalanceData();
        handleGetClientsData(1);
        setScrollPosition(0);
        return () => {
            setClients([]);
        }
    }, [billingPeriod]);

    useEffect(() => {
        filtersApplied && handleApplyFilters();
    }, [filtersApplied]);

    useEffect(() => {
        const activeOptions = sortOptions.filter(option => option.active);
        activeOptions.length > 0 && handleSort(activeOptions[0]);
    }, [sortOptions]);

    useEffect(() => {
        setStats([
            { id: 1, name: 'Total a cobrar en el mes', stat: formatterCurrency((generalBalance?.amount?.total_amount || 0 )/ 100), icon: CurrencyDollarIcon },
            { id: 2, name: 'Total cobrado en el mes', stat: formatterCurrency((generalBalance?.amount?.total_paid_amount || 0 )/ 100), icon: CurrencyDollarIcon },
            { id: 3, name: 'Porcentaje de cobranza', stat: `${(((generalBalance?.amount?.total_paid_amount || 0) * 100) / (generalBalance?.amount?.total_amount || 1)).toFixed(2)}%`, icon: ReceiptPercentIcon },
            { id: 4, name: 'Total de clientes', stat: totalClients, icon: UsersIcon }
        ])
    }, [generalBalance, totalClients]);

    const handleGetClientsData = async (page) => {
        setIsLoadingClients(true);
        try {
            setScrollPosition(0);
            setCurrenPage(page);

            const res = await getPortfolioClients(billingPeriod?.start, billingPeriod?.end, page, 10, searchInput, filterString, sortString);
            setPagination(paginate(res.total_items, 10, Math.ceil(res.total_items / res.limit)));
            const response = res.data.map(item => {
                var returnItem = {
                    ...item,
                    account: {
                        ...item.account,
                        tax_id: item?.account.taxpayer?.tax_id,
                        translated_account_type: accountType[item?.account?.account_type]
                    },
                    formatted_previous_amount: formatterCurrency((item?.amount?.previous_amount || 0) / 100),
                    formatted_amount: formatterCurrency((item?.amount?.total_amount || 0) / 100),
                    formatted_due_amount: formatterCurrency((item?.amount?.due_amount || 0) / 100),
                    formatted_payment_date: formatDate(item?.next_payment_date, 'DD MMM YY')
                }
                return returnItem
            });
            setClients(response);
            if (totalClients == 0) {
                setTotalClients(res.total_items);
            }
            setIsLoadingClients(false);
        } catch (e) {
            setClients([]);
            setTotalClients(0);
            setIsLoadingClients(false);
            setError(e.message || 'Hubo un error al obtener la información.');
        }
    }

    const handleGetBalanceData = async () => {
        setIsLoadingBalance(true);
        try {
            const res = await getGeneralBalance(billingPeriod?.start, billingPeriod?.end);
            setGeneralBalance(res);
            setIsLoadingBalance(false);
        } catch (e) {
            setIsLoadingBalance(false);
            setGeneralBalance(null);
            setTotalClients(0);
            setError(e.message || 'Hubo un error al obtener la información.');
        }
    }

    const handleSort = item => setSortString(sortItem(item));

    const handlePasteSearchInput = event => setSearchInput(event.clipboardData.getData('text'));

    const handleApplyFilters = () => {
        setFilterString(filterItem(filters));
        setFiltersApplied(false);
    }

    const handleCleanFilters = () => {
        const newFilters = filters.map(filter => {
            const newOptions = filter.options.map(option => {
                return {
                    ...option,
                    applied: false,
                    checked: false
                }
            });
            return {
                ...filter,
                options: newOptions
            }
        });
        setFilters(newFilters);
        setFiltersApplied(false);
        setFilterString('');
        setNotification(true);
        setTimeout(() => {
            setNotification(false);
        }, 5000);
    }

    return (
        <>
            <div className="w-full">
                <Nav user={user}>
                    <div className="w-full">
                        <span className="text-3xl text-white font-bold">
                            Cobranza
                        </span>
                    </div>
                </Nav>
            </div>
            <div className='min-h-full md:min-h-[calc(100vh-4rem)]'>
                {error &&
                    <div className='w-full sticky top-[68px] lg:top-1 mt-16 lg:mt-4 z-[35]'>
                        <LongAlert title={error} show={error != null} onClose={() => setError(null)} />
                    </div>
                }
                <Notification show={notification} message='Filtros limpiados correctamente' />
                <div className="w-full px-4 pt-4">
                    <div className="hidden lg:flex justify-between items-center w-full">
                        <h1 className="text-4xl font-bold text-left text-gray-900">
                            Cobranza
                        </h1>
                        <div className='w-full flex justify-end'>
                            <div className='w-full relative sm:max-w-md'>
                                <DatePicker
                                    selected={dateSelected}
                                    onChange={(date) => setDateSelected(date)}
                                    dateFormat="MMMM yyyy"
                                    className='w-full border-gray-300 rounded-md text-gray-900 py-2.5 text-sm cursor-pointer'
                                    wrapperClassName='w-full'
                                    calendarClassName='w-full shadow-md'
                                    disabledKeyboardNavigation
                                    showMonthYearPicker
                                    showFullMonthYearPicker
                                    maxDate={maxDate}
                                    disabled={isLoadingBalance || isLoadingClients}
                                    locale={locale}
                                />
                                <span className="pointer-events-none absolute inset-y-0 top-[.3rem] right-0 ml-3 flex flex-col items-center pr-2">
                                    <CalendarIcon className="min-h-[1.5rem] min-w-[1.5rem] text-v2-blue-text-login mt-1" aria-hidden="true" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`w-full px-4 py-0 md:py-4 mb-16 lg:mb-8 ${error != null ? 'mt-0' : 'mt-16 lg:mt-0'} `}>
                    <div className='w-full flex lg:hidden mb-4'>
                        <div className='w-full relative'>
                            <DatePicker
                                selected={dateSelected}
                                onChange={(date) => setDateSelected(date)}
                                dateFormat="MMMM yyyy"
                                className='w-full border-gray-300 rounded-md text-gray-900 py-2.5 text-sm cursor-pointer'
                                wrapperClassName='w-full'
                                calendarClassName='w-full shadow-md'
                                disabledKeyboardNavigation
                                showMonthYearPicker
                                showFullMonthYearPicker
                                maxDate={maxDate}
                                disabled={isLoadingBalance || isLoadingClients}
                                locale={locale}
                            />
                            <span className="pointer-events-none absolute inset-y-0 top-[.3rem] right-0 ml-3 flex flex-col items-center pr-2">
                                <CalendarIcon className="min-h-[1.5rem] min-w-[1.5rem] text-v2-blue-text-login mt-1" aria-hidden="true" />
                            </span>
                        </div>
                    </div>

                    <div className='mb-4'>
                        <Stats items={stats} isLoading={isLoadingBalance} />
                    </div>

                    {isLoadingClients ?
                        <>
                            <div className='w-full h-12 rounded-full bg-gray-300 animate-pulse'></div>
                            <div className='w-full flex justify-between'>
                                <div className='w-24 h-6 rounded-full bg-gray-300 animate-pulse my-4' />
                                <div className='w-24 h-6 rounded-full bg-gray-300 animate-pulse my-4' />
                            </div>
                        </>
                        :
                        <>
                            <SearchBar value={searchInput} setValue={setSearchInput} placeholder_mobile='Nombre, correo o RFC' placeholder_desktop='Nombre, correo o RFC' onPaste={handlePasteSearchInput} />
                            <section aria-labelledby="filter-heading" className='pt-4'>
                                <div className="flex items-center justify-between">
                                    <Sort options={sortOptions} title='Ordenar' setOptions={setSortOptions} />
                                    <div className='flex items-center gap-6'>
                                        <button type="button" className="flex items-center gap-1 text-sm font-medium text-gray-700 md:hidden" onClick={() => setOpenSlide(true)}>
                                            Filtros <span><AdjustmentsHorizontalIcon className='w-[18px] h-[18px]' /></span>
                                        </button>
                                        <Filters filters={filters} setFilters={setFilters} setFiltersApplied={setFiltersApplied} />
                                        {filterString !== '' && <span className='cursor-pointer text-sm font-medium text-gray-700 hover:text-gray-900 flex gap-1 pt-0 md:pt-[1px]' onClick={handleCleanFilters}>Limpiar filtros <TrashIcon className='h-5 h-5' /></span>}
                                    </div>
                                </div>
                            </section>
                        </>
                    }
                    {isLoadingClients ?
                        <Table title='Cobros' data={clients} isLoadingData={isLoadingClients} columns={tableColumns} />
                        :
                        <>
                            {clients.length > 0 ?
                                <div className='mt-4 space-y-4'>
                                    <Table title='Cobros' data={clients} isLoadingData={isLoadingClients} columns={tableColumns} />
                                    <div className='flex flex-col-reverse lg:flex-row justify-between'>
                                        <Pagination pages={pagination?.pages?.length} currentPage={currentPage} setCurrentPage={setCurrenPage} />
                                    </div>
                                </div>
                                :
                                <div className='mt-4'>
                                    <EmptyState title='No se encontraron clientes' text='Para visualizar la información, deben existir primero en el sistema, verifícalo.' />
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
            <SlideOver open={openSlide} setOpen={setOpenSlide} title='Filtros'>
                <Filters filters={filters} openSlide={openSlide} setFilters={setFilters} setOpenSlide={setOpenSlide} setFiltersApplied={setFiltersApplied} />
            </SlideOver>
        </>
    )
}

export default Portfolio;